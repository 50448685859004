import { tagOrNull } from './cacheTagUtils';

const typeToKeyMap = {
  embeddedArticle: 'article',
  embeddedCard: 'card',
  embeddedContentModule: 'contentModule',
  embeddedCuratedList: 'curatedList',
  embeddedImage: 'image',
  embeddedPlaymakerLiveVideo: 'playmakerLiveVideo',
  embeddedProduct: 'product',
  embeddedProductList: 'productList',
  embeddedRecipe: 'recipe',
  embeddedSlideshow: 'slideshow',
  embeddedTaxonomy: 'taxonomy',
  embeddedVideo: 'video',
  embeddedVideoPlaylist: 'videoPlaylist',
  embeddedWidget: 'widget',
};

/**
 * Maps article body properties to a function that pulls cache tag(s) from that property value
 */
const tagSelectors = {
  /**
   * @param {Article['id']} id
   */
  id: (id) => `article-${id}`,
  /**
   * @param {Article['primaryMedia']} primaryMedia
   */
  primaryMedia: (primaryMedia) => {
    const type = typeToKeyMap[primaryMedia?.type];
    const id = primaryMedia[type]?.id;

    return tagOrNull(type, id);
  },
  /**
   * @param {Article['taxonomy']} taxonomy
   */
  taxonomy: (taxonomy) => taxonomy?.allTerms?.map((term) => tagOrNull('taxonomy', term?.id)),
  /**
   * @param {Article['primaryImage']} image
   */
  primaryImage: (image) => tagOrNull('image', image?.id),
  /**
   * @param {Article['teaseImage']} image
   */
  teaseImage: (image) => tagOrNull('image', image?.id),
  /**
   * @param {Article['socialImage']} image
   */
  socialImage: (image) => tagOrNull('image', image?.id),
  /**
   * @param {Article['experts']} experts
   */
  experts: (experts) => experts?.map((expert) => tagOrNull('person', expert?.person?.id)),
  /**
   * @param {Article['authors']} authors
   */
  authors: (authors) => authors?.map((author) => tagOrNull('person', author?.person?.id)),
  /**
  * Pulls all cache tags from the article body
  * @param {Article['body']} articleBody
  * @returns {string[]}
  */
  body: (articleBody) => {
    const tags = [];

    articleBody?.forEach((element) => {
      const key = typeToKeyMap[element?.type];

      if (!key) {
        return;
      }

      tags.push(
        tagOrNull(key, element[key]?.id),
      );
    });

    return tags;
  },
  /**
   * @param {Article['source']} source
   */
  source: (source) => tagOrNull('organization', source?.organization?.id),
};

/**
 * Pulls all cache tags from an article. Tags are deduped.
 * @param {Article} article
 * @returns {string[]}
 */
export function getArticleCacheTags(article) {
  const tags = new Set();

  Object.keys(tagSelectors).forEach((articleKey) => {
    const articlePropValue = article[articleKey];

    if (!articlePropValue) {
      return;
    }

    const tagOrTags = tagSelectors[articleKey](articlePropValue);

    if (!tagOrTags) {
      return;
    }

    if (Array.isArray(tagOrTags)) {
      tagOrTags.filter(Boolean).forEach((tag) => tags.add(tag));
      return;
    }

    tags.add(tagOrTags);
  });

  return Array.from(tags);
}
